import axios from 'axios';
import store from '@/store';

const Api = axios.create({
  baseURL: process.env.VUE_APP_API + '/__admin',
  timeout: 36000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Request interceptor and inject token bearer
Api.interceptors.request.use(
  async config => {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + (await store.getters['userAuthStore/USERTOKENFIREBASE']),
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);
export default Api;
